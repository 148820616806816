import { FC } from 'react'
import { Typography } from '@mui/material'

// Styles
import styles from './TextBubble.module.scss'

type Props = {
  chromaticIgnore?: boolean
  title: string
  value: string
}

export const TextBubble: FC<Props> = (props) => {
  const { chromaticIgnore, title, value } = props

  return (
    <div className={styles.textBubble}>
      <Typography variant="smallText">{title}</Typography>
      <Typography data-chromatic={chromaticIgnore && 'ignore'} variant="smallTextBold">
        {value}
      </Typography>
    </div>
  )
}
